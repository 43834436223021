import { useEffect, useState } from "react";
import { getDetail } from "../api/filter.api";

export const useGetFilterDetail = (filterId: string) => {
  const [data, setdata] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const [error, seterror] = useState(false);

  useEffect(() => {
    getFilterDetail(filterId);
  }, []);

  const refreshFilterDetail = async () => {
    await getFilterDetail(filterId);
  };

  const getFilterDetail = async (filterId: string) => {
    setIsLoading(true);
    try {
      const data = await getDetail(filterId);
      setdata(data);
      setIsLoading(false);
    } catch (error) {
      seterror(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    filter: data,
    error,
    getFilterDetail,
    refreshFilterDetail,
  };
};
