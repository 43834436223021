import { useEffect, useState } from "react";
import Button from "../base/Button.base";
import "../styles/pages/AdminPointsRewardsScreen.scss";
import {
  createOrUpdatePointsRewardsParams,
  getPointsRewardsParams,
} from "../api/admin.api";
import { PointsRewards } from "../components/Admin/pointsRewards/PointsRewards";
import { InputPresets } from "../components/UI";

interface PointsRewardsParamsResponse {
  _id: string;
  name: string;
  description: string;
  type: string;
  valueType: string;
  value: string;
}

export default function AdminPointsRewardsScreen() {
  const initialParamsValues = {
    lowerLimit: 0,
    upperLimit: 10000,
    minFactor: 0,
    maxFactor: 0.95,
    scaleFactor: 0.001,
    scale: 1000,
    totalElements: 15,
    middlePoint: 5000,
  };

  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(initialParamsValues);

  useEffect(() => {
    getPointsRewardsParams()
      .then((data: PointsRewardsParamsResponse) => {
        const paramsResponse = JSON.parse(data.value);
        setParams({
          lowerLimit:
            paramsResponse.lowerLimit || initialParamsValues.lowerLimit,
          upperLimit:
            paramsResponse.upperLimit || initialParamsValues.upperLimit,
          maxFactor: paramsResponse.maxFactor || initialParamsValues.maxFactor,
          scaleFactor:
            paramsResponse.scaleFactor || initialParamsValues.scaleFactor,
          scale: paramsResponse.scale || initialParamsValues.scale,
          totalElements:
            paramsResponse.totalElements || initialParamsValues.totalElements,
          middlePoint:
            paramsResponse.middlePoint || initialParamsValues.middlePoint,
          minFactor: paramsResponse.minFactor || initialParamsValues.minFactor,
        });
      })
      .catch(() => alert("Error getting the parameters"))
      .finally(() => setLoading(false));
  }, []);

  const onSave = async () => {
    setLoading(true);
    try {
      await createOrUpdatePointsRewardsParams({
        lowerLimit: Number(params.lowerLimit),
        upperLimit: Number(params.upperLimit),
        maxFactor: Number(params.maxFactor),
        scaleFactor: Number(params.scaleFactor),
        scale: Number(params.scale),
        totalElements: Number(params.totalElements),
        middlePoint: Number(params.middlePoint),
        minFactor: Number(params.minFactor),
      });
    } catch (error) {
      alert("Error saving the parameters");
    } finally {
      setLoading(false);
    }
  };

  const onChange = (e: any) => {
    const validateNumber = /^\d+(\.\d*)?$/;
    const value: string = e.target.value || "";
    if (value === "" || value.match(validateNumber)) {
      setParams({ ...params, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="admin-home-container">
      <div className="admin-pointsRewards-container">
        <div className="admin-pointsRewards-form">
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Lower Limit"
            name="lowerLimit"
            type="text"
            value={params.lowerLimit}
            placeholder="0"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Middle Point"
            name="middlePoint"
            type="text"
            value={params.middlePoint}
            placeholder="5000"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Upper Limit"
            name="upperLimit"
            type="text"
            value={params.upperLimit}
            placeholder="0"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Min factor"
            name="minFactor"
            type="text"
            value={params.minFactor}
            placeholder="1"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Max factor"
            name="maxFactor"
            type="text"
            value={params.maxFactor}
            placeholder="1"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Scale factor"
            name="scaleFactor"
            type="text"
            value={params.scaleFactor}
            placeholder="0.001"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Scale"
            name="scale"
            type="text"
            value={params.scale}
            placeholder="10"
            onChange={onChange}
          />
          <InputPresets.TextInput
            className="admin-pointsRewards-form-input"
            label="Elements"
            name="totalElements"
            type="text"
            value={params.totalElements}
            placeholder="1000"
            onChange={onChange}
          />

          <Button
            isPrincipal
            className="admin-pointsRewards-form-button"
            loading={loading}
            onClick={onSave}
            value="Save"
          />
        </div>
        <PointsRewards
          {...{
            lowerLimit: Number(params.lowerLimit),
            upperLimit: Number(params.upperLimit),
            maxFactor: Number(params.maxFactor),
            scaleFactor: Number(params.scaleFactor),
            scale: Number(params.scale),
            totalElements: Number(params.totalElements),
            middlePoint: Number(params.middlePoint),
            minFactor: Number(params.minFactor),
          }}
        />
      </div>
    </div>
  );
}
