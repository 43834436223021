// import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from "axios";
// import { useContext } from "react";

// import { getAsyncStorage } from "../helpers/asyncStorage";
// import { AuthContext } from "../contexts/authContext/AuthContext";

// const baseURL= 'https://filtroo.herokuapp.com/backoffice'; //REMOTE

const baseURL = "https://api.filtroo.co";
// const baseURL = "http://192.168.0.198:8080";

const filtrooApi = axios.create({ baseURL: `${baseURL}/backoffice` });
export const baseFiltrooApi = axios.create({ baseURL });

export default filtrooApi;
