import { STORE } from "../constants/store.constants";
import filtrooApi from "./filtrooApi";

export const updatePassword = async (password: string, newPassword: string) => {
  return filtrooApi.post("/user/changepassword", {
    password,
    newPassword,
  });
};

export const getCategories = () => {
  return filtrooApi.get("/category");
};

export const CreateCompany = async (
  image: File[],
  name: string,
  idCategory: string,
  description: string,
  links: Array<{
    file: File[];
    type: string;
    link: string;
  }>
) => {
  const formData = new FormData();
  console.log("imagefile: ", image[0]);
  formData.append("imageFileCompany", image[0]);
  formData.append("imageFileLogo", image[0]);
  formData.append("name", name);
  formData.append("idCategory", idCategory);
  formData.append("description", description);
  links.forEach((i) => {
    formData.append("imageFilesAtt", i.file[0]);
    formData.append("attNames", i.type);
    formData.append("attUrls", i.link);
  });
  const res = await filtrooApi.post("/company/newcompany", formData);

  return res.data._id;
};

export const AddReward = (
  image: File[],
  name: string,
  coinsPrice: string,
  companyIdGenerated: string
) => {
  console.log("se añade reward: ", name, companyIdGenerated)
  const formData = new FormData();
  console.log("reward image file: ", image[0]);
  formData.append("imageFileReward", image[0]);
  formData.append("name", name);
  formData.append("coinsPrice", coinsPrice);
  formData.append("companyId", companyIdGenerated);
  filtrooApi.post("/rewards/newreward", formData);
};

export const addLink = (image: File[], name: string, url: string) => {
  const formData = new FormData();
  formData.append("file", image[0]);
  formData.append("name", name);
  formData.append("url", url);
  filtrooApi.post("/links/", formData);
};

export const onBoardingSubmit = async (
  password: { password: string; newPassword: string },
  Company: {
    image: any;
    name: string;
    idCategory: string;
    description: string;
    links: Array<{ file: any; type: string; link: string }>;
  },
  rewards: Array<{ image: any; name: string; coinsPrice: number }>,
  set: (key: any, value: any) => void,
  get: (key: any) => void
) => {

  return new Promise(async (resolve, reject) => {
    try {
      const companyIdGenerated = await CreateCompany(
        Company.image,
        Company.name,
        Company.idCategory,
        Company.description,
        Company.links
      );
      const user: any = get(STORE.USER);
      user.companyId = companyIdGenerated;
      set(STORE.USER, user);
      await Promise.all([
        rewards.map((i) => [
          AddReward(
            i.image,
            i.name,
            i.coinsPrice.toString(),
            companyIdGenerated
          ),
        ]),
      ]);
      resolve(companyIdGenerated);
    } catch (err) {
      reject(err);
    }
  });
};
