import React, { useState } from "react";
import { updatePassword } from "../../api/onBoarding.api";
import Button from "../../base/Button.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";

export default function ChangePassword(props: {
  onSubmit: any;
  state: any;
  setState: any;
  buttonValue?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { state, setState } = props;
  const {
    APP: { ON_BOARDING },
  } = useTranslation();

  const updateState = (value: string, name: string) =>
    setState((prevState: any) => ({ ...prevState, [name]: value }));

  const handleSubmit = () => {
    setLoading(true);

    updatePassword(state.currentPassword, state.rePassword)
      .then(() => {
        setLoading(false);
        props.onSubmit(state);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setError(err);
      });
  };
  return (
    <div className="on-boarding-children">
      <div style={{ marginTop: 30 }}>
        <span>{ON_BOARDING._1.CURRENT}</span>
        <Input
          type="password"
          name="currentPassword"
          isFull
          value={state.currentPassword}
          placeholder={ON_BOARDING._1.CURRENT}
          style={{ marginTop: 5 }}
          onChange={updateState}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <span>{ON_BOARDING._1.PASSWORD}</span>
        <Input
          type="password"
          name="password"
          isFull
          value={state.password}
          style={{ marginTop: 5 }}
          placeholder={ON_BOARDING._1.PASSWORD}
          onChange={updateState}
        />
        <Input
          type="password"
          name="rePassword"
          isFull
          value={state.rePassword}
          style={{ marginTop: 15 }}
          placeholder={ON_BOARDING._1.REPEAT}
          onChange={updateState}
        />
      </div>
      <Button
        loading={loading}
        disabled={
          state.rePassword.length < 8 || state.rePassword != state.password
        }
        style={{ marginTop: 30 }}
        value={props.buttonValue || ON_BOARDING.CONTINUE}
        onClick={handleSubmit}
        isPrincipal
      />
    </div>
  );
}
