import React from "react";
import { AuthProvider } from "./contexts/authContext/AuthContext";
import { LoaderProvider } from "./contexts/LoaderContext";
import { LocalizeProvider } from "./contexts/LocalizeContext";
import { StoreProvider } from "./contexts/StoreContext";
import { AppRouter } from "./routers/AppRouter";

const AppState = ({ children }: any) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export const FiltrooBackofficeApp = () => {
  return (
    <LoaderProvider>
      <StoreProvider>
        <LocalizeProvider>
          <AppState>
            <AppRouter />
          </AppState>
        </LocalizeProvider>
      </StoreProvider>
    </LoaderProvider>
  );
};
