import React, { useEffect, useState } from "react";
import Button from "../base/Button.base";
import BannerUsers from "../components/Admin/users/BannerUsers";
import { useTranslation } from "../contexts/LocalizeContext";
import DatagridUsers from "../components/Admin/users/DatagridUsers";
import "../styles/pages/adminHome.scss";
import {
  getUserCountryStatistics,
  getUserOsStatistics,
  getUsers,
  getUserTypeStatistics,
} from "../api/admin.api";
import classNames from "classnames";


export default function AdminHomeScreen() {
  const [loading, setLoading] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  const [users, setUsers] = useState<any>({
    users: [],
    lastUserId: "",
    firstUserId: "",
    usersTypeStatistics: {
      totalUsers: 0,
      freeUsers: 0,
      goldUsers: 0,
    },
    usersOsStatistics: {
      androidUsersCount: 0,
      iosUsersCount: 0,
    },
    usersCountryStatistics: [],
  });

  const {
    APP: { ADMIN },
  } = useTranslation();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUsers(),
      getUserTypeStatistics(),
      getUserOsStatistics(),
      getUserCountryStatistics(),
    ])
      .then((data) => {
        const [
          users,
          usersTypeStatistics,
          usersOsStatistics,
          usersCountryStatistics,
        ] = data;
        setUsers({
          users: users.data,
          lastUserId: users.data[users.data.length - 1]._id,
          firstUserId: users.data[0]._id,
          usersTypeStatistics: usersTypeStatistics.data,
          usersOsStatistics: usersOsStatistics.data,
          usersCountryStatistics: usersCountryStatistics.data,
        });
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const handlePagination = (type: string): void => {
    setLoading(true);

    switch (type) {
      case "next":
        getUsers(users.lastUserId, "up")
          .then(({ data: nextUsers }) => {
            setLoading(false);
            if (nextUsers.length !== 0) {
              setUsers({
                ...users,
                users: nextUsers,
                firstUserId: nextUsers[0]._id,
                lastUserId: nextUsers[nextUsers.length - 1]._id,
              });
            }
          })
          .catch((err) => console.error(err));
        break;

      case "prev":
        getUsers(users.firstUserId, "down")
          .then(({ data: prevUsers }) => {
            setLoading(false);
            if (prevUsers.length !== 0) {
              setUsers({
                ...users,
                users: prevUsers,
                firstUserId: prevUsers[0]._id,
                lastUserId: prevUsers[prevUsers.length - 1]._id,
              });
            }
          })
          .catch((err) => console.error(err));
        break;

      default:
        break;
    }
  };

  return (
    <div className="admin-home-container">
      <div className={classNames("admin-users-container",{overlay:showOverview})}>
        <BannerUsers
          freeUsers={users.usersTypeStatistics.freeUsers}
          goldUsers={users.usersTypeStatistics.goldUsers}
        />

        <DatagridUsers
          showOverview={showOverview}
          users={users.users}
          handlePagination={handlePagination}
          usersCountryStatistics={users.usersCountryStatistics}
          usersOsStatistics={users.usersOsStatistics}
          usersTotal={users.usersTypeStatistics.totalUsers}
        />

        <div className="admin-users-container-buttons">
          <Button
            onClick={() => setShowOverview(!showOverview)}
            isPrincipal
            style={{ marginBottom: 10 }}
            value={
              showOverview
                ? ADMIN.USERS.OVERVIEW.CLOSE
                : ADMIN.USERS.OVERVIEW.OPEN
            }
            loading={loading ? true : false}
          />
          <Button
            disabled
            onClick={() => console.log("Export")}
            isPrincipal
            style={{ marginBottom: 10 }}
            value={ADMIN.USERS.EXPORT}
            loading={loading ? true : false}
          />
        </div>
      </div>
    </div>
  );
}
