import { User } from "../../interfaces/appInterfaces";

export interface AuthState {
  status: "authenticated" | "unauthorized";
  token: string | null;
  errorMessage: string;
  user: User | null;
}

type AuthAction =
  | { type: "signIn"; payload: { token: string; user: User } }
  | { type: "addError"; payload: string }
  | { type: "removeError" }
  | { type: "notAuthenticated" }
  | { type: "logout" };

export const AuthReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "addError":
      return {
        ...state,
        user: null,
        status: "unauthorized",
        token: null,
        errorMessage: action.payload,
      };
    case "removeError":
      return {
        ...state,
        errorMessage: "",
      };
    case "signIn":
      return {
        ...state,
        errorMessage: "",
        status: "authenticated",
        token: action.payload.token,
        user: action.payload.user,
      };
    case "logout":
    case "notAuthenticated":
      return {
        ...state,
        status: "unauthorized",
        token: null,
        user: null,
      };
    default:
      return state;
  }
};
