import { FC, useEffect, useState } from "react";
import { Styles } from "./SalesScreenView.styles";
import useToast from "../../../hooks/useToast";
import { useForm } from "../../../hooks/useForm";
import { AlertColor } from "@mui/material/Alert";
import { ButtonPresets, InputPresets, TextPresets } from "../../../components/UI";
import { SelectPresets } from "../../../components/UI/Select";
import { Card, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

interface SalesScreenProps {
  onSubmit?: (data: any) => Promise<any | undefined>;
  showAlert: boolean;
  statusAlert: AlertColor;
  msg: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowAlert: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStatusAlert: any;
}

const SalesScreenView: FC<SalesScreenProps> = ({
  onSubmit,
  showAlert,
  statusAlert,
  msg,
  setShowAlert,
  setStatusAlert,
}) => {
  
  const { Toast, handleClick, handleClose } = useToast();
  const [ errors, setErrors ] = useState({
    emailError: "",
    amountError: "",
    operationError: ""
  })
  const initialValues: any = {
    email: "",
    amount: "",
    operation: ""
  };

  const { form, onChange, setFormValue } = useForm(initialValues);


  const resetErrors = () => {
    setErrors({
      emailError: "",
      amountError: "",
      operationError: ""
    })
  }
  useEffect(() => {
    if (showAlert) {
      handleClick();
    }
  }, [showAlert]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        handleClose();
        setShowAlert(false);
        setStatusAlert("");
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  const handleSubmit = async () => {
    if (
      form.email === "" ||
      form.amount === "" ||
      Number(form.amount) === 0
    ) {
      if(!form.email){
        setErrors(prevValue => ({
          ...prevValue,
          emailError: "Email is required"
        }))
      }

      if(!form.amount){
        setErrors(prevValue => ({
          ...prevValue,
          amountError: "Amount is required"
        }))
      }

      if(!form.operation){
        setErrors(prevValue => ({
          ...prevValue,
          operationError: "Operation type is required"
        }))
      }


      return;
    }
    const created = await onSubmit?.(form);

    if (created) {
      setFormValue(initialValues);
    }
  };


  const columns: GridColDef[] = [
    // {
    //   field: "name",
    //   headerName: "Name",
    //   minWidth: 160,
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "name",
      minWidth: 80,
      flex: 0.55,
    },
    {
      field: "email",
      headerName: "email",
      minWidth: 120,
      flex: 0.65,
    },
    {
      field: "points",
      headerName: "points",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      minWidth: 160,
      flex: 1,
    },
  ];
  

  const navigate = useNavigate()

  return (
    <Styles>
      <div className="SalesForm">
        <div className="SalesForm__Content">
          <TextPresets.TitleH2 className="SalesForm__Title">
            Generate points
          </TextPresets.TitleH2>
          
          <InputPresets.TextInput
            className="SalesForm__Input"
            placeholder="Email"
            error={errors.emailError}
          
            value={form.email}
            onChange={(e:any) => {
              resetErrors()
              onChange(e.target.value, "email");
            }}
          />

          <InputPresets.TextInput
            className="SalesForm__Input"
            placeholder="Amount of points"
            value={form.amount}
            error={errors.amountError}
            onChange={(e:any) => {
              const value = e.target.value;
              if (
                value === "" ||
                /^\d+(\.\d*)?$/.test(value) ||
                /^\d*\.$/.test(value)
              ) {
                resetErrors()
                onChange?.(value, "amount");
              }
            }}
          />

        <div className="DB_Charts-TypeComponent">
            <TextPresets.BodyBold style={{maxWidth:"15rem", minWidth:"15rem", textAlign:"center", marginRight:25}}>
              Operation type
            </TextPresets.BodyBold>
            <SelectPresets.SelectInput
              placeholder="Select operation type"
              options={[{value:"add", label:"Add these points" }, {value:"set", label:"Replace the ones the user has"}]}
              onChangeValue={(value) =>{
                if(value === ""){
                  onChange("", "operation");
                }else{
                  onChange(value , "operation")}}
                }
              error={errors.operationError}
              value={form.operation}
              style={{ padding: "0.25rem 0", maxWidth:"25rem" }}
              
            />
          </div>
        </div>
        
        <div className="SalesForm__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="SalesForm__Actions-button"
            label="Confirm"
            onClick={handleSubmit}
          />
        </div>
      </div>
      {showAlert && (
        <Toast onClose={handleClose} severity={statusAlert}>
          {msg}
        </Toast>
      )}
    </Styles>
  );
};

export default SalesScreenView;
