import React, { useContext, useMemo, useState } from "react";
import {
  BarChart,
  BarChart2,
  Home,
  LogOut,
  PieChart,
  Link,
} from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/authContext/AuthContext";
import { useTranslation } from "../contexts/LocalizeContext";
import "../styles/components/leftMenu.scss";

const Tab = (props: {
  id: string;
  icon: React.ReactNode;
  onClick: any;
  withText?: string;
  active?: boolean;
}) => (
  <button
    style={props.active ? { backgroundColor: "#4592d3" } : {}}
    key={props.id}
    onClick={props.onClick}
    className="left-menu-container-b"
  >
    {props.icon}
    {props.withText && <span>{props.withText}</span>}
  </button>
);

export default function LeftMenu() {
  const { signOut } = useContext(AuthContext);
  const { LAYOUT } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs] = useState([
    {
      id: "home",
      icon: <Home style={{ color: "#FFF" }} />,
      url: "/home",
    },
    {
      id: "dashboard",
      icon: <BarChart2 style={{ color: "#FFF" }} />,
      url: "/statistics",
    },
    {
      id: "links",
      icon: <Link style={{ color: "#FFF" }} />,
      url: "/links",
    },
    {
      id: "user",
      icon: <PieChart style={{ color: "#FFF" }} />,
      url: "/userstatistics",
    },

  ]);

  const renderTabs = useMemo(
    () =>
      tabs.map((i) => (
        <Tab
          key={i.id}
          active={i.url === location.pathname}
          onClick={() => navigate(i.url)}
          {...i}
        />
      )),
    [tabs, location]
  );

  return (
    <aside className="left-menu-container">
      <div className="left-menu-container-t">{renderTabs}</div>
      <Tab
        withText={LAYOUT.TABS.LOG_OUT}
        id="LOGOUT"
        onClick={signOut}
        icon={<LogOut style={{ color: "#FFF" }} />}
      />
    </aside>
  );
}
