import React, { useState, useEffect, useCallback } from "react";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";
import GoBack from "../../base/GoBack.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import "../../styles/pages/onBoarding.scss";
import { getCategories } from "../../api/onBoarding.api";
import filtrooApi from "../../api/filtrooApi";

//Mocks Categories
// TODO: Conectar con mongo aca las categorias a mostrar
// const categories = ["Restaurant", "Music", "Games"];

export default function RestaurantInfo(props: {
  onSubmit: any;
  onBack: any;
  state: any;
  setState: any;
  categories: any;
}) {
  const { setState, state, categories } = props;
  const {
    APP: { ON_BOARDING },
  } = useTranslation();
  const updateState = (value: any, name: string) =>
    setState((prevState: any) => ({ ...prevState, [name]: value }));

  return (
    <div className="on-boarding-children">
      <Dragger
        defaultImage={state?.url}
        style={{ width: 150, height: 150, marginTop: 30 }}
        onChange={(file: File, url: string) => {
          updateState(file, "image");
          updateState(url, "url");
        }}
      />
      <Input
        placeholder={ON_BOARDING._2.NAME}
        name="name"
        isFull
        value={state.name}
        style={{ marginTop: 15 }}
        onChange={updateState}
      />
      <Input
        placeholder={ON_BOARDING._2.CATEGORY}
        name="idCategory"
        isSelector
        isFull
        value={state.idCategory}
        style={{ marginTop: 15 }}
        onChange={updateState}
        options={categories}
      />
      <Input
        isArea
        isFull
        placeholder={ON_BOARDING._2.DESCRIPTION}
        name="description"
        value={state.description}
        style={{ marginTop: 15 }}
        onChange={updateState}
      />
      <div className="on-boarding__buttons">
        <GoBack onClick={props.onBack} />
        <Button
          isPrincipal
          value={ON_BOARDING.CONTINUE}
          onClick={props.onSubmit}
          disabled={!state.url || !state.name || !state.description}
        />
      </div>
    </div>
  );
}
