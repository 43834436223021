import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import "../styles/pages/ApprovedFilterScreen.scss";
import { useFilters } from "../hooks/useFilters";
import { useNavigate } from "react-router-dom";
import { Card, Loader } from "semantic-ui-react";

export default function ApprovedFilterScreen() {
  const navigate = useNavigate();
  const { filters, isLoading } = useFilters();

  const filtersMock = [
    {
      _id: "1",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "2",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "3",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "4",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "5",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "6",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
  ];
  if (isLoading) {
    return <Loader active={true}>Loading...</Loader>;
  }

  return (
    <div className="First_Container">
      {
        filters.length ? (<div className="container">
        <Swiper
          navigation={true}
          modules={[Navigation, Autoplay]}
          slidesPerView={filters && Math.min(5, filters.length)}
          className="swiper-container"
        >
          {filters &&
            filters.map((filter: any) => (
              <SwiperSlide className="swiper-slide" key={filter._id}>
                <Card
                  className="card"
                  onClick={() => navigate(`/admin/filters/${filter._id}`)}
                >
                  <img
                    src={filter.image}
                    alt="filter"
                    className="card__image"
                  />

                  <h3 className="card__title">{filter.name}</h3>
                </Card>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>) : 
      <div>
        <h3>There are no filters to validate</h3>
      </div>
      }
      
    </div>
  );
}
