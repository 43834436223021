import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { STORE } from "../../constants/store.constants";
import { AuthContext } from "../../contexts/authContext/AuthContext";
import { useStore } from "../../contexts/StoreContext";
import AdminLeftMenu from "./AdminLeftMenu";
import Navbar from "../Navbar";

export default function AdminLayout() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("users");
  const { status } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    console.log(status);
    if (status === "unauthorized") {
      navigate("/", { replace: true });
    }
    setTab(location.pathname.substring(location.pathname.lastIndexOf("/")+1));
  }, [location]);

  return (
    <div className="layout-container">
      <Navbar withDropdown breadcrumb={tab} />
      <section className="layout-container__b">
        <AdminLeftMenu />
        <div></div>
        <main className="layout-container__b-r">
          <div
            style={{
              width: "100%",
              position: "sticky",
              top: 60,
              height: 1,
              backgroundColor: "#E7E7E7",
              marginBottom: 10,
            }}
          />
          {status === "authenticated" && <Outlet />}
        </main>
      </section>
    </div>
  );
}
