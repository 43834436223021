import React from "react";
import { Edit2 } from "react-feather";
import Button from "../../base/Button.base";
import GoBack from "../../base/GoBack.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import { RewardsResponse } from "../../interfaces/appInterfaces";
import LinkCard from "../cards/Link.card";
import PreviewCard from "../cards/PreviewCard";
import RewardCard from "../cards/Reward.card";

export default function Preview(props: {
  onEdit: any;
  onBack: any;
  onSubmit: any;
  account: {
    url: string;
    name: string;
    description: string;
    idCategory: string;
  };
  rewards: Array<Partial<RewardsResponse>>;
  links: Array<any>;
  categories: Array<any>;
}) {
  const {
    APP: {
      ON_BOARDING,
      ON_BOARDING: { _4 },
    },
  } = useTranslation();
  return (
    <div className="on-boarding-preview">
      <span>{_4.TITLE}</span>
      <PreviewCard title={_4.ACCOUNT.TITLE} onEdit={() => props.onEdit(2)}>
        <div>
          <div className="on-boarding-preview__input">
            <label>{ON_BOARDING._4.ACCOUNT.LOGO}</label>
            <div
              className="on-boarding-preview__input-img"
              style={{ marginTop: 6 }}
            >
              <img
                className="on-boarding-preview__input-img"
                src={props.account.url}
              />
            </div>
          </div>
          <div className="on-boarding-preview__input">
            <label>{ON_BOARDING._4.ACCOUNT.CATEGORY}</label>
            <h3>
              {props.categories &&
                props.categories.find((c) => c._id === props.account.idCategory)
                  .name}
            </h3>
          </div>
          <div className="on-boarding-preview__input">
            <label>{ON_BOARDING._4.ACCOUNT.NAME}</label>
            <h3>{props.account.name}</h3>
          </div>
          <div className="on-boarding-preview__input">
            <label>{ON_BOARDING._4.ACCOUNT.DESCRIPTION}</label>
            <h3>{props.account.description}</h3>
          </div>
        </div>
      </PreviewCard>
      <PreviewCard title={_4.REWARDS.TITLE} onEdit={() => props.onEdit(3)}>
        {props.rewards.map((i: Partial<RewardsResponse>) => (
          <RewardCard showActive={false} {...i} />
        ))}
      </PreviewCard>
      <PreviewCard title={_4.LINKS.TITLE} onEdit={() => props.onEdit(4)}>
        {props.links.map((i: any) => (
          <LinkCard {...i} />
        ))}
      </PreviewCard>
      <div style={{ width: "100%", height: 160 }} />
    </div>
  );
}
