import { AxiosError } from "axios";
import filtrooApi, { baseFiltrooApi } from "./filtrooApi";
import { Filter } from "../interfaces/appInterfaces";

export const getAllFilterCategories = async () => {
  try {
    const response = await baseFiltrooApi.get(`/categoryfilter/all`);

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllFiltersToApprove = async (): Promise<Filter[]> => {
  try {
    const response = await filtrooApi.get(`/creators/getallnotapproved`);

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDetail = async (filterId: string) => {
  try {
    const response = await filtrooApi.get(
      `/creators/filter-detail/${filterId}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const approvedFilter = async (
  filterId: string,
  status: string,
  rejectReason?: string[]
) => {
  try {
    const response = await filtrooApi.put(`/creators/approved/${filterId}`, {
      status,
      rejectReason,
    });

    if (response.data.msg === "filter updated") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error approving filter");
  }
};

export const uploadFilter = async (
  name: string,
  filterLocation: string,
  category: string,
  filterUrl: string,
  previewUrl: string,
  videoUrl: string
) => {
  try {
    await baseFiltrooApi.post(`/filter/add-filter`, {
      name: name,
      location: filterLocation,
      filterUrl,
      previewUrl,
      videoUrl,
      category,
    });

    return "SUCCESS";
  } catch (_error) {
    const error = _error as any;
    if (error.response?.data) {
      switch (error.response.data.msg) {
        case "Invalid name":
          return "INVALID_NAME";
        case "Invalid filter url":
          return "INVALID_FILTER_URL";
        case "Invalid image preview url":
          return "INVALID_PREVIEW_URL";
        case "Invalid video url":
          return "INVALID_VIDEO_URL";
        case "Invalid category":
          return "INVALID_CATEGORY";
        case "Category not found":
          return "CATEGORY_NOT_FOUND";
        case "Filter already exists in DB":
          return "FILTER_ALREADY_EXISTS";
        case "User filtroo.co not found":
          return "CREATOR_NOT_FOUND";
        default:
          return "UNKNOWN";
      }
    }
    console.error(error);
    return "UNKNOWN";
  }
};
