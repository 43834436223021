import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import { useTranslation } from "../contexts/LocalizeContext";
import "../styles/pages/onBoarding.scss";
import Input from "../base/Input.base";
import Button from "../base/Button.base";
import useResize from "../hooks/useResize";
import ChangePassword from "../components/onBoarding/ChangePassword";
import RestaurantInfo from "../components/onBoarding/RestaurantInfo";
import Rewards from "../components/onBoarding/Rewards";
import { RewardsResponse } from "../interfaces/appInterfaces";
import RewardCard from "../components/cards/Reward.card";
import Preview from "../components/onBoarding/Preview";
import Publish from "../components/onBoarding/Publish";
import Links from "../components/onBoarding/Links";
import LinkCard from "../components/cards/Link.card";
import { useStore } from "../contexts/StoreContext";
import { useNavigate } from "react-router-dom";
import { onBoardingSubmit } from "../api/onBoarding.api";
import { STORE } from "../constants/store.constants";
import { getCategories } from "../api/onBoarding.api";

export default function OnBoarding() {
  const ref = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const { get, set } = useStore();
  const navigate = useNavigate();
  const {
    APP: { ON_BOARDING },
  } = useTranslation();
  const dimensions = useResize();
  const [step, setStep] = useState(1);
  const [x, setX] = useState(0);
  const [height, setHeight] = useState(300);

  useEffect(() => {
    const session = get("TOKEN");
    if (!session) {
      navigate("/", { replace: true });
    }
  }, []);

  const [firstStepForm, setFirstStepForm] = useState({
    currentPassword: "",
    password: "",
    rePassword: "",
  });
  const [secondStepForm, setSecondStepForm] = useState<any>({
    image: "",
    url: "",
    name: "",
    idCategory: "",
    description: "",
  });

  const [rewards, setRewards] = useState<Array<Partial<RewardsResponse>>>([]);
  const [links, setLinks] = useState<Array<any>>([]);

  useEffect(() => {
    if (step != 3 && step != 4) {
      setX((ref?.current?.clientWidth - 400) / 2 - 30);
    } else {
      setX((ref?.current?.clientWidth - 400) / 2 - 220);
    }
  }, [dimensions, step]);

  useEffect(() => {
    switch (step) {
      case 1:
        setHeight(425);
        break;
      case 2:
        setHeight(600);
        break;
      case 3:
        setHeight(420);
        break;
    }
  }, [step]);

  const [categories, setCategories] = useState<any>(null);

  useEffect(() => {
    if (!categories) {
      getCategories().then((res) => {
        setCategories(res.data);
        setSecondStepForm((prevState: any) => ({
          ...prevState,
          idCategory: res.data[0]._id,
        }));
      });
    }
  }, []);

  const handleSubmit = () => {
    console.log("secondStepForm", secondStepForm);
    setLoading(true);
    onBoardingSubmit(
      {
        password: firstStepForm.currentPassword,
        newPassword: firstStepForm.rePassword,
      },
      { ...secondStepForm, links },
      rewards.map((i: any) => ({ ...i, image: i.file })),
      set,
      get
    )
      .then((companyIdGenerated) => {
        setLoading(false);
        navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("secondStepForm", secondStepForm);
  }, [secondStepForm]);

  const renderCard = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <ChangePassword
            state={firstStepForm}
            setState={setFirstStepForm}
            onSubmit={() => setStep(2)}
          />
        );
      case 2:
        return (
          <RestaurantInfo
            state={secondStepForm}
            setState={setSecondStepForm}
            onBack={() => setStep(1)}
            onSubmit={() => setStep(3)}
            categories={categories}
          />
        );
      case 3:
        return (
          <Rewards
            rewards={rewards}
            onAdd={(reward: Partial<RewardsResponse>) =>
              setRewards((prev) => [...prev, reward])
            }
            onBack={() => setStep(2)}
            onSubmit={() => setStep(4)}
          />
        );
      case 4:
        return (
          <Links
            links={links}
            onAdd={(link: any) => setLinks((prev) => [...prev, link])}
            onBack={() => setStep(3)}
            onSubmit={() => setStep(5)}
          />
        );
      case 5:
      default:
        return (
          <Preview
            links={links}
            rewards={rewards}
            account={secondStepForm}
            onBack={() => setStep(4)}
            onSubmit={handleSubmit}
            onEdit={setStep}
            categories={categories}
          />
        );
    }
  }, [step, firstStepForm, secondStepForm, rewards, links]);

  const renderRewards = useMemo(
    () =>
      rewards.length > 0 ? (
        rewards.map((i: Partial<RewardsResponse>, index) => (
          <RewardCard
            key={i?.name + "" + index}
            onDelete={() => {
              const arr = [...rewards];
              const newArr = arr.filter(
                (item, itemIndex) => itemIndex != index
              );
              setRewards(newArr);
            }}
            onEdit={(state) => {
              const arr = [...rewards];
              const newArr = arr.map((item, itemIndex) => {
                if (itemIndex == index) {
                  return state;
                }
                return item;
              });
              setRewards(newArr);
            }}
            withEdit
            showActive={false}
            {...i}
          />
        ))
      ) : (
        <div className="on-boarding__rewards-empty">
          <p>{ON_BOARDING._3.EMPTY}</p>
        </div>
      ),
    [rewards]
  );

  const renderLinks = useMemo(
    () =>
      links.length > 0 ? (
        links.map((i, index) => (
          <LinkCard
            key={index + i.link + ""}
            onEdit={(state) => {
              const arr = [...links];
              const newArr = arr.map((item, itemIndex) => {
                if (itemIndex === index) {
                  return state;
                }
                return item;
              });
              setLinks(newArr);
            }}
            withEdit
            onDelete={() => {
              const arr = [...links];
              const newArr = arr.filter(
                (item, itemIndex) => itemIndex != index
              );
              setLinks(newArr);
            }}
            {...i}
          />
        ))
      ) : (
        <div className="on-boarding__rewards-empty">
          <p>{ON_BOARDING._4.EMPTY}</p>
        </div>
      ),
    [links]
  );

  return (
    <div ref={ref}>
      <Navbar />
      <div className="on-boarding-container">
        {step === 5 && (
          <Publish
            loading={loading}
            step={step}
            onBack={() => {
              setStep(4);
            }}
            onSubmit={handleSubmit}
          />
        )}
        <div className="on-boarding__card-container">
          <motion.div className="on-boarding__card-holder">
            <motion.div
              key="card"
              className="on-boarding__card"
              style={
                step === 5
                  ? {
                      backgroundColor: "#FFFFFF00",
                      boxShadow: "none",
                      padding: 0,
                      paddingTop: 30,
                    }
                  : {}
              }
              animate={{
                x,
                height,
                scale: 1,
                rotate: 0,
              }}
            >
              <div>
                <h2>
                  {ON_BOARDING.STEPS} {step} {ON_BOARDING.OF} 5
                </h2>
              </div>
              {renderCard}
            </motion.div>
            {step === 3 && (
              <motion.div
                className="on-boarding__rewards-container"
                animate={{
                  x: x + 15,
                  scale: 1,
                  rotate: 0,
                }}
              >
                {renderRewards}
              </motion.div>
            )}
            {step === 4 && (
              <motion.div
                className="on-boarding__rewards-container"
                animate={{
                  x: x + 15,
                  scale: 1,
                  rotate: 0,
                }}
              >
                {renderLinks}
              </motion.div>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
