// const restaurantResponse = await filtrooApi.get<RestaurantResponse[]>(
//     `/restaurants`
//   );
//   const filterResponse = await filtrooApi.get<FilterResponse[]>(
//     `/filters/byrestaurant`
//   );
//   const rewardResponse = await filtrooApi.get<RewardsResponse[]>(
//     `/rewards/byrestaurant`
//   );

import filtrooApi from "./filtrooApi";

export const getRestaurantData = (id: string) =>
  filtrooApi.get(`/company/${id}`);

export const getFilter = (id: string) =>
  filtrooApi.get(`/filters/byrestaurant/${id}`);

export const getRewards = (id: string) =>
  filtrooApi.get(`/rewards/byrestaurant/${id}`);

export const deleteReward = (id: string) =>
  filtrooApi.delete(`/rewards/delete/${id}`);

export const updateRewardPrice = (id: string, coinsPrice: string) =>
  filtrooApi.post(`/rewards/changecoinsprice/${id}`, { coinsPrice });

export const updateRewardName = (id: string, name: string) =>
  filtrooApi.post(`/rewards/changename/${id}`, { name });

export const updateRewardImage = (
  id: string,
  actualUrlImage: string,
  imageFileReward: File[]
) => {
  const formData = new FormData();
  formData.append("actualUrlImage", actualUrlImage);
  formData.append("imageFileReward", imageFileReward[0]);
  return filtrooApi.post(`/rewards/changeimage/${id}`, formData);
};
