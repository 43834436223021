import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { calculateFactorPointsRewards } from "../../../helpers/calculateFactorPointsRewards";

interface PointsRewardsProps {
  scale?: number;
  totalElements?: number;
  lowerLimit?: number;
  upperLimit?: number;
  minFactor?: number;
  maxFactor?: number;
  scaleFactor?: number;
  middlePoint?: number;
}

export const PointsRewards = ({
  scale = 1000,
  totalElements = 10,
  lowerLimit = 0,
  upperLimit = 10000,
  minFactor = 0,
  maxFactor = 0.95,
  scaleFactor = 0.001,
  middlePoint = 5000,
}: PointsRewardsProps) => {
  // Crear un array con las cantidades de puntos actuales
  const currentPointsArray = Array.from(
    { length: totalElements },
    (_, index) => index * scale
  );

  // Calcular los factores de castigo correspondientes
  const factoresCastigoArray = currentPointsArray.map((points) =>
    calculateFactorPointsRewards(
      points,
      lowerLimit,
      middlePoint,
      upperLimit,
      minFactor,
      maxFactor,
      scaleFactor
    )
  );

  // Combinar datos en un formato reconocido por Recharts
  const data = currentPointsArray.map((currentPoints, index) => ({
    currentPoints,
    punishFactor: factoresCastigoArray[index],
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="currentPoints"
          label={{
            value: "Puntos Generados Diariamente",
            position: "insideBottom",
            offset: -5,
          }}
        />
        <YAxis
          label={{
            value: "Factor de Castigo",
            angle: -90,
            position: "insideLeft",
          }}
          domain={[0, 1]}
        />
        <Tooltip />
        <Legend align="right" verticalAlign="bottom" />
        <Line
          type="monotone"
          dataKey="punishFactor"
          stroke="#52a9f5"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
