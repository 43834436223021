export const calculateFactorPointsRewards = (
  points = 0,
  lowerLimit = 0,
  middlePoint = 5000,
  upperLimit = 10000,
  minFactor = 0,
  maxFactor = 0.95,
  scaleFactor = 0.001
): number => {
  if (!middlePoint) {
    middlePoint = upperLimit / 2;
  }

  if (points <= lowerLimit) {
    return minFactor;
  } else if (sigmoid(points, scaleFactor, middlePoint) < maxFactor) {
    return sigmoid(points, scaleFactor, middlePoint);
  } else {
    return maxFactor;
  }
};

const sigmoid = (points: number, a = 0.001, b: number): number => {
  //donde a es la pendiente y b es el punto medio
  return 1 / (1 + Math.exp(-a * (points - b)));
};
