import LogoGold from '../../../assets/Isotipo_Dorado.png';
import LogoFree from '../../../assets/Isotipo_Azul.png';

export interface IBannerUsers {
  freeUsers: number;
  goldUsers: number;
}

const calcPercentage = (value:number, total:number) => ((value / total)*100).toFixed(2);

const BannerUsers = ({ freeUsers = 0, goldUsers = 0 }: IBannerUsers) => {
  return (
    <div className="banner-users">
      <div className="banner-users-item banner-users-side">
        <div className="flex-1"><img className="banner-users-side-icon" src={LogoFree} alt="freeIcon" /></div>
        <div className="flex-2">
          <p className="title">Free Users</p>
          <p className="number">{freeUsers.toLocaleString("es-ES")}</p>
          <p className="percentage">{calcPercentage(freeUsers,(freeUsers + goldUsers))}%</p>
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="banner-users-item banner-users-center">
        <p className="title">Total Users</p>
        <p className="number">
          {(freeUsers + goldUsers).toLocaleString("es-ES")}
        </p>
      </div>
      <div className="banner-users-item banner-users-side">
        <div className="flex-1"><img className="banner-users-side-icon" src={LogoGold} alt="goldIcon" /></div>
        <div className="flex-2">
          <p className="title">Gold Users</p>
          <p className="number">{goldUsers.toLocaleString("es-ES")}</p>
          <p className="percentage">{calcPercentage(goldUsers,(freeUsers + goldUsers))}%</p>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  );
};

export default BannerUsers;
