import React, { useEffect, useState } from "react";
import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input from "../base/Input.base";
import Rewards from "../components/Rewards";
import { useTranslation } from "../contexts/LocalizeContext";
import { useForm } from "../hooks/useForm";
import { useRestaurantFull } from "../hooks/useRestaurantFull";
import "../styles/pages/home.scss";
import { X } from "react-feather";
import { newReward } from "../functions/RestaurantFunctions";
import { getFilter, getRestaurantData, getRewards } from "../api/home.api";
import { useStore } from "../contexts/StoreContext";
import { STORE } from "../constants/store.constants";
import { useNavigate } from "react-router-dom";

export default function HomeScreen() {
  const [loading, setLoading] = useState(true);
  const [isUploadingReward, setIsUploadingReward] = useState(false);
  const [restaurant, setRestaurant] = useState<any>({
    id: "",
    rewards: [],
    description: "",
    logo: "",
    name: "",
    attachments: [],
  });
  const navigate = useNavigate();
  const {
    APP: { HOME },
  } = useTranslation();
  const { get } = useStore();

  useEffect(() => {
    const user = get(STORE.USER);
    Promise.all([
      getRestaurantData(user?.companyId),
      getFilter(user?.companyId),
      getRewards(user?.companyId),
    ])
      .then((data) => {
        const [restaurant, filter, rewards] = data;
        console.log(restaurant.data, filter.data, rewards.data);
        setRestaurant({
          ...restaurant.data,
          id: user?.companyId,
          rewards: rewards.data,
          filter: filter.data,
        });
        setLoading(false);
        setIsUploadingReward(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { name, description, coins, onChange, form } = useForm({
    name: "",
    description: "",
    coins: "",
  });

  const [imageFile, setImageFile] = useState<File[] | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  const submitNewReward = async () => {
    try {
      setIsUploadingReward(true);
      if (!name || !coins) {
        setIsUploadingReward(false);
        return setErrorMessage("Empty field.");
      }
      if (!imageFile) {
        setIsUploadingReward(false);
        return setErrorMessage("Image file empty.");
      }
      if (imageFile![0].size > 1500000) {
        setIsUploadingReward(false);
        return setErrorMessage("Max image size 1.5MB");
      }
      if (imageFile![0].type === "image/jpeg" || "image/png") {
        console.log("success");
      }
      // submit new reward
      const companyId = get(STORE.USER).companyId;
      const reward = await newReward(companyId, name, coins, imageFile);
      onChange("", "name");
      onChange("", "description");
      onChange("", "coins");
      setImageFile(null);
      setImageUrl("");
      setIsUploadingReward(false);
      setRestaurant((prev: any) => ({
        ...prev,
        rewards: [...prev.rewards, reward],
      }));
    } catch {
      setIsUploadingReward(false);
    }
  };

  return (
    <div className="home-container">
      <Rewards
        onEdit={(id: string, reward: any) => {
          const arr = [...restaurant.rewards];
          const rewards = arr.map((i) => {
            if (i._id === id) {
              return reward;
            }
            return i;
          });
          setRestaurant((prev: any) => ({
            ...prev,
            rewards,
          }));
        }}
        onDelete={(id: string) => {
          const arr = [...restaurant.rewards];
          const rewards = arr.filter((i) => i._id != id);
          setRestaurant((prev: any) => ({
            ...prev,
            rewards,
          }));
        }}
        cards={restaurant.rewards}
      />
      <form>
        <h1>{HOME.REWARDS.TITLE}</h1>
        <p>{HOME.REWARDS.PARAGRAPH}</p>
        <Input
          name="name"
          value={name}
          maxLength={50}
          type="text"
          isFull
          placeholder={HOME.REWARDS.FORM.NAME}
          onChange={(value: any, name: any) => {
            onChange(value, name);
          }}
          style={{ marginTop: 15 }}
        />
        <div style={{ width: 200 }}>
          <Input
            name="coins"
            value={coins}
            type="number"
            isFull
            placeholder={HOME.REWARDS.FORM.COINS}
            onChange={(value: any, coins: any) => {
              onChange(value, coins);
            }}
            style={{ marginTop: 15 }}
          />
        </div>

        <Dragger
          defaultImage={imageUrl}
          style={{ marginTop: 15, marginBottom: 20 }}
          onChange={(file: any, url: string) => {
            setImageFile(file);
            setImageUrl(url);
          }}
        />

        {errorMessage && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
          >
            <span>{errorMessage}</span>
            <X
              style={{ color: "red", marginLeft: 5 }}
              size={18}
              onClick={() => setErrorMessage(null)}
            />
          </div>
        )}

        <Button
          onClick={submitNewReward}
          isPrincipal
          style={{ marginBottom: 30 }}
          value={HOME.REWARDS.FORM.CREATE}
          loading={isUploadingReward ? true : false}
        />
      </form>
    </div>
  );
}
