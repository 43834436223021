import React, { useRef, useState } from "react";
import { Edit2, X } from "react-feather";
import { Link } from "react-router-dom";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import { RewardsResponse } from "../../interfaces/appInterfaces";
import "../../styles/components/reward.scss";
import Modal from "../Modal";

export default function LinkCard(props: {
  type: string;
  image: string;
  link: string;
  withEdit?: boolean;
  onDelete?: () => void;
  onEdit?: (v: any) => void;
  key: any;
}) {
  const {
    APP: { ON_BOARDING, MODALS, HOME },
  } = useTranslation();
  const editModal = useRef<any>(null);
  const deleteModal = useRef<any>(null);
  const [state, setState] = useState({
    image: props.image,
    file: null,
    type: props.type,
    link: props.link,
  });
  const updateState = (value: string, name: any) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  return (
    <div key={props.key}>
      <Modal ref={editModal} title={MODALS.EDIT.LINKS.TITLE}>
        <div>
          <Dragger
            defaultImage={state?.image}
            onChange={(file: any, url: string) => {
              setState((prevState) => ({
                ...prevState,
                image: url,
                file,
              }));
            }}
          />
          <Input
            isSelector
            name="type"
            type="text"
            isFull
            value={state.type}
            placeholder={HOME.LINKS.TYPE}
            onChange={updateState}
            style={{ marginTop: 15 }}
          />
          <Input
            name="link"
            type="text"
            isFull
            value={state.link}
            placeholder={HOME.LINKS.LINK}
            onChange={updateState}
            style={{ marginTop: 15 }}
          />
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            disabled={
              !state.type || !state.image || !state.link || !state.image
            }
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(state);
                editModal.current.close();
              }
            }}
            value={MODALS.EDIT.UPDATE}
          />
        </div>
      </Modal>
      <Modal ref={deleteModal} title={MODALS.DELETE.LINKS.TITLE}>
        <div>
          <span>{MODALS.DELETE.SURE}</span>
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            onClick={() => {
              if (props.onDelete) {
                props.onDelete();
                deleteModal.current.close();
              }
            }}
            value={MODALS.DELETE.DELETE}
          />
        </div>
      </Modal>
      <div className="reward-card-container">
        {props?.withEdit && (
          <div className="reward-card-container-fab">
            <div className="reward-card-container-fab__holder">
              <button onClick={() => editModal.current.open()}>
                <Edit2 />
              </button>
              <button
                onClick={() => deleteModal.current.open()}
                className="reward-card-container-fab__holder--delete"
              >
                <X />
              </button>
            </div>
          </div>
        )}
        <div className="reward-card-container-l">
          <img className="reward-card-container-l" src={props.image} />
        </div>
        <div className="reward-card-container-r">
          <p className="reward-card-container-r-t">{props.type}</p>
          <div className="reward-card-container-r-b">
            <a href={props?.link}>
              <Button
                isPrincipal
                value={ON_BOARDING.OPEN}
                className="on-boarding__buttons-add"
                style={{ backgroundColor: "#FFF", width: 150 }}
                onClick={() => {
                  window.open(props.link, "_blank");
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
