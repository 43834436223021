import { useEffect, useState } from "react";

import { Filter } from "../interfaces/appInterfaces";
import { getAllFiltersToApprove } from "../api/filter.api";

export const useFilters = () => {
  const [data, setdata] = useState<Filter[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [error, seterror] = useState(false);

  useEffect(() => {
    getFiltersByCategoryAndOwner();
  }, []);

  const refreshFilters = async () => {
    await getFiltersByCategoryAndOwner();
  };

  const getFiltersByCategoryAndOwner = async () => {
    setIsLoading(true);
    try {
      const data = await getAllFiltersToApprove();
      setdata(data);
      setIsLoading(false);
    } catch (error) {
      seterror(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    filters: data,
    error,
    getFiltersByCategoryAndOwner,
    refreshFilters,
  };
};
