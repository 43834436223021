import filtrooApi from "../api/filtrooApi";

export const changeRestaurantName = async (id: string, name: string) => {
  try {
    const res = await filtrooApi.post(`/company/changename/${id}`, {
      name: name,
    });
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const changeRestaurantDescription = async (
  id: string,
  description: string
) => {
  try {
    const res = await filtrooApi.post(`/company/changedescription/${id}`, {
      description: description,
    });
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const changeRestaurantImage = async (
  id: string,
  imageFile: File,
  actualUrlImage: string,
  name: string
) => {
  console.log("se intenta mandar a back: ", id, imageFile, actualUrlImage);

  try {
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    formData.append("actualUrlImage", actualUrlImage);
    const res = await filtrooApi({
      method: "post",
      url: `backoffice/company/changerestaurantimage/${id}/${name}`,
      data: formData,
    });
    console.log(res);
    return res;
  } catch (error: any) {
    console.log(error.response);
    return error;
  }
};

export const newReward = async (
  companytId: string,
  name: string,
  coins: string,
  imageFile: File[]
) => {
  try {
    const formData = new FormData();
    formData.append("imageFileReward", imageFile[0]);
    formData.append("companyId", companytId);
    formData.append("name", name);
    formData.append("coinsPrice", coins);
    formData.append("coins", coins);
    const res = await filtrooApi.post(`rewards/newreward`, formData);

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
