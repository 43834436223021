import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import { useContext } from "react";
import { AuthContext } from "../contexts/authContext/AuthContext";
import AuthLayout from "../components/AuthLayout";
import Input from "../base/Input.base";
import { useTranslation } from "../contexts/LocalizeContext";
import Checkbox from "../base/Checkbox.base";
import { Link } from "react-router-dom";
import Button from "../base/Button.base";
import Logo from "../assets/BlancoyNegroLogo.png";
import "../styles/pages/login.scss";
import { X } from "react-feather";

export const LoginScreen = () => {
  const {
    AUTH: { LOG_IN },
  } = useTranslation();
  const { signIn, errorMessage, removeError } = useContext(AuthContext);
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { email, password, onChange } = useForm({
    email: "",
    password: "",
  });

  const submit = () => {
    setLoading(true);
    signIn({ email, password }).finally(() => setLoading(false));
  };

  return (
    <AuthLayout>
      <form className="login-container">
        <img src={Logo} alt="filtroo logo" style={{ maxWidth: 250 }} />
        <h1 className="login-container__title">{LOG_IN.TITLE}</h1>
        <Input
          name="email"
          value={email}
          type="text"
          onChange={onChange}
          placeholder={LOG_IN.FORM.EMAIL}
        />
        <Input
          style={{ marginTop: 25 }}
          value={password}
          type="password"
          name="password"
          onChange={onChange}
          placeholder={LOG_IN.FORM.PASSWORD}
        />
        <div className="login-container__options">
          <div className="login-container__options-l">
            <Checkbox
              checked={checked}
              onChange={() => setChecked((prev) => !prev)}
            />
            <span>{LOG_IN.REMEMBER}</span>
          </div>
          <Link className="login-container__options-r" to="/">
            <span>{LOG_IN.FORGOT}</span>
          </Link>
        </div>
        <Button
          loading={loading}
          style={{ marginTop: 60 }}
          onClick={submit}
          value={LOG_IN.BUTTON}
        />
        {errorMessage.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 15,
              color: "red",
              fontWeight: "bold",
            }}
          >
            <span>{errorMessage}</span>
            <X
              style={{ color: "red", marginLeft: 5 }}
              size={18}
              onClick={removeError}
            />
          </div>
        )}
      </form>
    </AuthLayout>
  );
};
