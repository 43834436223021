import React, { useMemo } from "react";
import {
  deleteReward,
  updateRewardImage,
  updateRewardName,
  updateRewardPrice,
} from "../api/home.api";
import { useLoader } from "../contexts/LoaderContext";
import { useTranslation } from "../contexts/LocalizeContext";
import { RewardsResponse } from "../interfaces/appInterfaces";
import RewardCard from "./cards/Reward.card";

export default function Rewards(props: {
  cards: Array<RewardsResponse>;
  onDelete: (id: string) => void;
  onEdit: (id: string, payload: any) => void;
}) {
  const {
    APP: {
      HOME: { REWARDS },
    },
  } = useTranslation();
  const { setLoading, loading } = useLoader();

  const renderRewards = useMemo(
    () =>
      props.cards.map((i) => (
        <RewardCard
          onDelete={() => {
            setLoading(true);
            deleteReward(i._id)
              .then(() => {
                setLoading(false);
                props.onDelete(i._id);
              })
              .catch((err) => {
                setLoading(false);
                console.error(err);
              });
          }}
          onEdit={async (state) => {
            try {
              setLoading(true);
              let image = i.image;
              const promises = [];
              if (i.coinsPrice != state.coinsPrice) {
                promises.push(updateRewardPrice(i._id, state.coinsPrice));
              }
              if (i.name != state.name) {
                promises.push(updateRewardName(i._id, state.name));
              }
              if (state.file) {
                image = await (
                  await updateRewardImage(i._id, i.image, state.file)
                ).data.newImageUrl;
              }
              Promise.all(promises)
                .then(() => {
                  setLoading(false);
                  props.onEdit(i._id, { ...state, image, _id: i._id });
                })
                .catch((err) => {
                  setLoading(false);
                  console.error(err);
                });
            } catch (err) {
              console.error(err);
            }
          }}
          withEdit
          showActive
          {...i}
          key={i._id}
        />
      )),
    [props.cards, loading]
  );

  return (
    <div className="reward-container">
      <h2>{REWARDS.CARD}</h2>
      <div className="reward-container__cards">{renderRewards}</div>
    </div>
  );
}
