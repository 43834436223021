import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors"

export const Styles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  min-height: 80vh;

  .DBHeader {

  }

  .DB_Charts {
    &-Container {
      width: 100%;
      max-width: 600px;
      background-color: #52a9f5;
      padding: 2rem;
    }
    &-GenerateComponent {
      display: flex;
      flex-direction:row;
      justify-content: space-around;
      
    }
    &-PointsComponent {
      margin-top:10px;
      display: flex;
      flex-direction:row;
      justify-content: space-around;
      align-items: center;
      color: white;
    }

    &-TypeComponent {
      margin-top:10px;
      display: flex;
      flex-direction:row;
      justify-content: space-around;
      align-items: center;
      color: white;
    }

    &-Search-Input {
      max-width: 12rem;
      height: 40px;
      background-color: ${GrayScale.lightOverDark};
    }

    &-SecondContainer {
      width: 100%;
      max-width: 600px;
      padding: 2rem;
      display: flex;
      justify-content: center;
    }
  }
`;
